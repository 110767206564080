import Dexie from "dexie";

const db = new Dexie("OUG_Surveys");

db.version(3).stores({
    currentState: "++id",
    surveys: "++id"
});

// check for a currentState DataObject
db.currentState
    .where("id")
    .equals(1)
    .toArray((result) => loadInitialState(result));

export default db;

function loadInitialState(result) {
    // If result is not an array ( no record found for id = 1) otherwise add it
    if (!result[0]) {
        db.currentState.add({
            id: 1,
            Contact: {
                Contact_id: 0,
            },
            Date: "",
        });
    }
}