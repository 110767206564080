<template>
    <v-app-bar v-if="!$route.meta.hideNavigation" app color="#962283" dark dense>
      <v-toolbar-title>
          <v-btn 
            color="white"
            text
            rounded
            class="my-2"
            :to="'/'"
         >{{Title}}</v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
          v-for="link in links"
          :key="`${link.label}-header-link`"
          color="white"
          text
          rounded
          class="my-2"
          :to="link.url"
        >
          {{ link.label }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon medium to="/Login">
        <v-avatar size="40px" item >
          <img
            alt="OUG"
            :src="getAvatar()"
          />
        </v-avatar>
      </v-btn>
    </v-app-bar>
</template>

<script>
import Settings from "../Data/Settings";
import { authComputed, getUser } from "../veux/helpers";

export default {
    name: 'Header',
    components: {
            
    },
  props: {
    Title: {
      Type: String,
      Required: true
    }
  },
  data() {
    return {
        links: Settings.mainmenu
    }
  },
  methods: {
    getAvatar() {
      // Set default to not logged in
      let result = require('../assets/logo.png')
      if (this.loggedIn) {
          // if logged in then check Profile photo
          (this.currentUser.Profile_Photo) 
          ? result = this.currentUser.Profile_Photo 
          : result = require('../assets/no_profile_picture.png')
      }
      return result
    },
  },
  computed: {
    ...authComputed,
    ...getUser,
  },
}
        
</script>

<style lang="scss" scoped>

</style>