import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import db from "../Data/Database";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        current_survey: null,
    },
    mutations: {
        SET_USER_DATA(state, userData) {
            state.user = userData;
            // Update the currentState DB
            db.currentState
                .update(1, {
                    Contact: userData.contact,
                    token: userData.OUG_token,
                })
                .catch((e) => {
                    console.error(e + " ocurred updating currentState!");
                });

            localStorage.setItem("user", JSON.stringify(userData));
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${userData.OUG_token}`;
        },
        CLEAR_USER_DATA() {
            db.currentState.update(1, {
                Contact: {},
                token: null,
            });
            localStorage.removeItem("user");
            location.reload();
        },
        SET_SURVEY_DATA(state, surveyData) {
            state.current_survey = surveyData;
            // Update the currentState DB
            db.currentState
                .update(1, {
                    Current_Survey: surveyData,
                })
                .catch((e) => {
                    console.error(e + " ocurred updating currentState!");
                });

            localStorage.setItem("survey", JSON.stringify(surveyData));
        },

    },
    actions: {
        register({ commit }, credentials) {
            return axios
                .post(
                    "https://server.ownurgoal.net.au/oug_app/OUG_Register",
                    credentials
                )
                .then(({ data }) => {
                    commit("SET_USER_DATA", data);
                });
        },
        reset({ commit }, credentials) {
            return axios
                .post(
                    "https://server.ownurgoal.net.au/oug_app/OUG_Reset_Credentials",
                    credentials
                )
                .then(({ data }) => {
                    commit("CLEAR_USER_DATA", data);
                });
        },
        login({ commit }, credentials) {
            return axios
                .post(
                    "https://server.ownurgoal.net.au/oug_app/OUG_App_login",
                    credentials
                )
                .then(({ data }) => {
                    commit("SET_USER_DATA", data.data);
                });
        },
        logout({ commit }) {
            commit("CLEAR_USER_DATA");
        },
        load_surveys({ commit }) {
            let Contact_id = JSON.parse(localStorage.getItem("user")).contact.Contact_id;

            let search_contact = { "Contact_id": Contact_id }
            return axios
                .post(
                    "https://server.ownurgoal.net.au/oug_app/GetSurveys",
                    search_contact,
                )
                .then(({ data }) => {
                    commit("SET_SURVEY_DATA", data);
                });
        },
    },
    getters: {
        loggedIn(state) {
            return !!state.user;
        },
        currentUser(state) {
            return state.user.contact;
        },
    },
});