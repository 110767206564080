import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./veux/store";
import axios from "axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;
const options = {
    // You can set your default toast options here
    timeout: 2000,
    position: "top-center",
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
};

Vue.use(Toast, options);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    axios,
    vuetify,
    created() {
        const userString = localStorage.getItem("user");
        if (userString) {
            const userData = JSON.parse(userString);
            this.$store.commit("SET_USER_DATA", userData);
        }
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    this.$store.dispatch("logout");
                }
                return Promise.reject(error);
            }
        );
    },
    render: (h) => h(App)
}).$mount("#app");