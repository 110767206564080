<template>
  <v-app>
    <Header Title="OUG Survey Platform"/>
    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer Title="OUG Survey Platform"/>
  </v-app>
</template>

<script>
import Header from "./components/Header.vue"
import Footer from "./components/Footer.vue"

export default {
  name: "App",
  components: {
    Header,
    Footer,
  }
};

</script>
