<template>
    <v-footer v-if="!$route.meta.hideNavigation" app color="oug" dark elevation="24">
      <v-row class="copy" no-gutters>
        <v-col cols="6" align="start">
          &copy; {{ new Date().getFullYear() }} —
          <strong>{{ Title }}</strong>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="oug"
          fab
          dark
          x-small
          to="/SurveysJS"
        >
          <v-icon>mdi-television</v-icon>
        </v-btn>
        <v-btn
          color="oug"
          fab
          dark
          x-small
          @click="logout"
        >
          <v-icon>mdi-lock</v-icon>
        </v-btn>
      </v-row>
    </v-footer>
</template>

<script>
import Settings from "../Data/Settings";

export default {
    name: 'Header',
    components: {
            
    },
  props: {
    Title: {
      Type: String,
      Required: true
    }
  },
  data() {
    return {
        links: Settings.mainmenu
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {},
}
        
</script>

<style lang="scss" scoped>

</style>