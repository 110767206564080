export default {
    mainmenu: [
        {
            label: 'Surveys',
            url: '/Surveys'
        },
        {
            label: 'Creator',
            url: '/Creator'
        },
        {
            label: 'PDF Export',
            url: '/Exportpdf'
        },
        {
            label: 'Analytics',
            url: '/Analytics'
        },
        {
            label: 'Results',
            url: '/Analyticstabulator'
        },
        {
            label: 'Listings',
            url: '/Analyticsdatatables'
        }
    ]
}

