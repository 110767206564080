import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/Home"),
  },
  {
    path: "/Login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../common/Login"),
  },
  {
    path: "/Register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "../common/Register"),
  },
  {
    path: "/Reset",
    name: "Reset",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../common/Reset"),
  },
  {
    path: "/SurveysJS/:title,:id,:postid",
    name: "Show JS Surveys",
    component: () =>
      import(/* webpackChunkName: "SurveysJS" */ "../views/SurveysJS"),
    meta: { hideNavigation: true },
  },
  {
    path: "/Surveys",
    name: "List Your Surveys",
    component: () =>
      import(/* webpackChunkName: "Surveys" */ "../views/Surveys"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Creator",
    name: "Survey Creator",
    component: () =>
      import(/* webpackChunkName: "Creator" */ "../views/Creator"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Exportpdf",
    name: "Export Surveys to PDF",
    component: () =>
      import(/* webpackChunkName: "Exportpdf" */ "../views/Exportpdf"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Analytics",
    name: "Analytics for your Surveys",
    component: () =>
      import(/* webpackChunkName: "Analytics" */ "../views/Analytics"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Analyticstabulator",
    name: "Survey Results",
    component: () =>
      import(/* webpackChunkName: "Analyticstabulator" */ "../views/Analyticstabulator"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Analyticsdatatables",
    name: "List Your Survey Results",
    component: () =>
      import(/* webpackChunkName: "Analyticsdatatables" */ "../views/Analyticsdatatables"),
    meta: { requiresAuth: true },
  },
];


const router = new VueRouter({
  mode: "history",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!localStorage.getItem("user");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      Vue.$toast.error(`You must be logged in to access this menu item !`, {
        timeout: 2000,
      });
      next("/Login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;